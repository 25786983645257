<template>
  <div class="grid sm:grid-cols-2 md:grid-cols-3 md:flex items-start justify-center md:space-x-16 flex-wrap gap-5 py-8 sm:py-16">
    <div v-for="(item, index) in footerLabels" :key="`ft-${getLocaleString(item.name)}-${index}`" class="flex flex-col items-center pt-4 sm:pt-0 sm:pl-6 justify-center sm:max-w-[300px]">
      <div class="inline-flex items-center justify-center h-16 w-16 rounded-full flex-shrink-0 fill-primary opacity-60" :style="{'background-color': item.bgColor}">
        <NuxtImg class="size-10 sm:size-12 flex items-center justify-center" :src="item.icon" />
      </div>

      <div class="text-center text-base text-primary-500 font-bold flex-grow-0">
        {{ getLocaleString(item.name) }}
      </div>

      <div class="text-center text-base text-primary-400 font-medium" v-if="item?.description && getLocaleString(item.description)">
        {{ getLocaleString(item.description) }}
      </div>
    </div>
  </div>
</template>

<script setup>
const storeConfig = useStoreConfig()
const { footerLabels } = storeToRefs(storeConfig)
</script>